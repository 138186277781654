import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import { CssBaseline, IconButton } from "@mui/material";
import { useLocation } from "@reach/router";
import { Amplify } from "aws-amplify";
import { navigate } from "gatsby";
import { SnackbarProvider } from "notistack";
import {
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import Intercom from "react-intercom";
import axiosClient, {
  checkIsPrivateRoute,
} from "../../api-services/axiosClient";
import { INITIAL_APP_STATE, NOT_DASHBOARD_ROUTES } from "../../commons/enum";
import { APP_LAYOUT_TYPES } from "../../commons/layout-types.constants";
import DataLoadingContext from "../../context/data-loading-context";
import {
  useIsAuthenticatedRedirectPages,
  useIsTablet,
} from "../../hooks/common.hooks";
import { useCompanyInfo } from "../../hooks/company.hooks";
import "../../scss/account.scss";
import "../../scss/contact.scss";
import "../../scss/layout.scss";
import "../../scss/login.scss";
import { getCsrfToken, logout } from "../../utils/auth";
import { currentSession } from "../../utils/user-auth-provider";
import SEO from "../SEO";
import SideBar from "../SideBar";
import { AuthenticatedRedirect } from "../authenticated-redirect";
import Footer from "../footer";
import LoadingBackdrop from "../loading-backdrop";
import SharingLayout from "../sharing-layout";
import SignupBanner from "../signup-banner-left";
import { DashboardLogicWrapper } from "./dashboard-logic-wrapper";
import UserAuthenticationNavigate from "./user-authentication-navigate";

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response) {
      if (
        error.response.status === 401 &&
        error.response.data?.message === "Unauthorized" &&
        error.config.url.indexOf("logout") < 0
      ) {
        try {
          const session = await currentSession();
          const apiConfig = {
            ...error.config,
            headers: {
              ...error.config.headers,
              authentication: session.accessToken.jwtToken,
            },
          };
          axiosClient.defaults.headers.common.authentication =
            session.accessToken.jwtToken;
          return axiosClient(apiConfig);
        } catch (err) {
          if (err === "No current user" && checkIsPrivateRoute()) {
            logout();
          }
        }
      }
      if (
        error.response.status === 403 &&
        error.response.data?.errorCode === "CFRSTOKEN_EXPIRED"
      ) {
        const csrfToken = await getCsrfToken();
        const apiConfig = {
          ...error.config,
          headers: {
            ...error.config.headers,
            "csrf-token": csrfToken,
          },
        };
        axiosClient.defaults.headers.common["csrf-token"] = csrfToken;
        return axiosClient(apiConfig);
      }
      throw error.response;
    }
    throw error;
  }
);

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.GATSBY_COGNITO_REGION,
    userPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_COGNITO_APP_CLIENT_ID,
  },
});

const cache = createCache({
  key: "experity",
  prepend: true,
});

const getStatusDataReducer = (state, action) => {
  switch (action.type) {
    case "appState":
      return { ...state, ...action.value };
    default:
      throw new Error("Unexpected action");
  }
};

const initializeApp = async (pathname) => {
  try {
    await getCsrfToken();
  } catch (err) {
    const isInDashboardRoutes = NOT_DASHBOARD_ROUTES.every(
      (item) => pathname.indexOf(item) < 0
    );
    if (isInDashboardRoutes) {
      navigate("/login");
    }
  }
};
const SnackBarActionItem = ({ handleClose = () => {} }) => {
  return (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );
};

const Layout = ({ children, location, pageContext }) => {
  const [appState, appStateDispatch] = useReducer(
    getStatusDataReducer,
    INITIAL_APP_STATE
  );
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const localStorageData = JSON.parse(
    decodeURIComponent(urlParams.get("data"))
  );
  const setAppState = (data) =>
    appStateDispatch({ type: "appState", value: data });
  const [isShowIntercom, setIsShowIntercom] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const { isLoading } = useCompanyInfo();

  const isTablet = useIsTablet();

  const pathname = location.pathname.replace(/\//g, "");
  const isAuthenticatedRedirectPages = useIsAuthenticatedRedirectPages(
    pathname
  );
  const pageLayoutRef = useRef(null);

  const notistackRef = useRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  if (typeof window !== "undefined") {
    const vh = window.innerHeight * 0.01;
    pageLayoutRef.current?.style.setProperty("--vh", `${vh}px`);
  }
  const onResizeHandler = () => {
    const vh = window.innerHeight * 0.01;
    pageLayoutRef.current?.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", onResizeHandler);
    }
  }, []);

  useLayoutEffect(() => {
    if (localStorageData) {
      const entries = Object.entries(localStorageData);
      entries.forEach(([key, value]) => {
        localStorage.setItem(key, value);
      });
    }
    setIsInitializing(true);
    initializeApp(location.pathname).finally(() => {
      setIsInitializing(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPage = () => {
    switch (pageContext.layout) {
      case APP_LAYOUT_TYPES.LOGIN_LAYOUT: {
        const page = (
          <div className="page-root__body" ref={pageLayoutRef}>
            <div className="login-wrapper">
              {!isTablet && <SignupBanner />}
              {children}
              <Footer />
            </div>
          </div>
        );

        if (isAuthenticatedRedirectPages) {
          return (
            <UserAuthenticationNavigate location={location}>
              <AuthenticatedRedirect redirectTo="/">
                {page}
              </AuthenticatedRedirect>
            </UserAuthenticationNavigate>
          );
        }
        return page;
      }
      case APP_LAYOUT_TYPES.SHARING_LAYOUT: {
        return <SharingLayout>{children}</SharingLayout>;
      }
      case APP_LAYOUT_TYPES.ERROR_LAYOUT: {
        return children;
      }
      default: {
        return (
          <UserAuthenticationNavigate location={location}>
            <DashboardLogicWrapper>
              <div className="page-root__container">
                <div className="page-root__side-bar">
                  <SideBar location={location} />
                </div>
                <div className="page-root__body" ref={pageLayoutRef}>
                  <div className="page-root__body-content">{children}</div>
                  <Footer />
                </div>
                {isShowIntercom && <Intercom appID="skt381tt" />}
              </div>
            </DashboardLogicWrapper>
          </UserAuthenticationNavigate>
        );
      }
    }
  };

  return (
    <DataLoadingContext.Provider
      value={{
        ...appState,
        setAppState,
        setIsShowIntercom,
        isShowIntercom,
      }}
    >
      <SnackbarProvider
        ref={notistackRef}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={2000}
        action={(key) => (
          <SnackBarActionItem handleClose={onClickDismiss(key)} />
        )}
        maxSnack={2}
      >
        <CssBaseline />
        <SEO />
        <CacheProvider value={cache}>
          {/* Your component tree. Now you can override MUI's styles. */}
          {isInitializing || isLoading ? <LoadingBackdrop /> : renderPage()}
        </CacheProvider>
      </SnackbarProvider>
    </DataLoadingContext.Provider>
  );
};

export default Layout;
